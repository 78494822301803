<template>
<div class="m-together">
<a-row id="Together" >
    <a-col>
        <img :src="pngImg" width="100%" alt="Test" @click="jumpUrl">
       <a-row class="togeter-button">
            <a-col :span="3" @click="up_info"><img :src="pngLeft" class="click-btn" alt=""></a-col>
            <a-col :span="9" @click="up_info" :style="pngLeftTextStyle">{{pngLeftText}}</a-col>
            <a-col :span="9" @click="next_info" :style="pngRightTextStyle">{{pngRightText}}</a-col>
            <a-col :span="3" @click="next_info"><img :src="pngRight" class="click-btn" alt=""></a-col>
        </a-row>

        <div class="togeter-background">

        </div>
        <div class="togeter-text text-left">
            <a-col :span="12" @click="next_info" class="mobile-togeter-button"><img :src="pngRightBack" class="click-btn" alt=""></a-col>
            <div v-if="1 === imgIndex" class="togeter-text-zh">
                <span class="together-title">寻找我们 </span><br />
                <br />
                创新斗室位处香港目前最大的科研基地香港科技园。香港有望成为亚洲下一个创新枢纽，香港科技园正为实现这个目标贡献我们的力量。我们在具战略意义的大湾区建立科技发展的大本营，致力造福世界。香港科技园是敢于实现梦想的创业家的理想孵化园地，又是连系环球各行各业和市场商机的平台。不管你是创业家还是企业持份人，香港科技园都会为你提供最前沿的基础设施、知识、合作机会和支援，通过创新及科技取得成功。
            </div>
            <div v-else-if="2 === imgIndex">
                <span class="together-title">社区环境</span><br /><br />
                
                    <p class="togeter-text-zh">
                        位处宁静美丽的吐露港，香港科技园为创新斗室提供<a style="text-decoration:underline;color:#000;" href="https://www.hkstp.org/our-community/park-amenities/" target="_blank" ><b>多元便利设施</b></a>，无论多国美食、超级市场、银行中心或健康服务，你都能方便地在园区内找到你需要的服务。香港科技园会所更为租户提供专属服务，你可使用桑拿室、游泳池、健身中心及运动室，助你放松及卸下疲劳。
                    </p>
                <a-row class="toget-icon-boax" >
                    <a-col :span="8">
                        <div class="subTime-img-text images001">
                            超级市场
                        </div>
                    </a-col>
                    <a-col :span="8">
                    <div class="subTime-img-text images004">会所</div>
                    </a-col>
                    <a-col :span="8">
                    
                    </a-col>

                    </a-row>
                    <a-row >
                        <a-col :span="8" >
                        <div class="subTime-img-text images003">医疗保健</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images002">银行</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images005">餐厅</div>
                        </a-col>

                    </a-row>
            </div>
            <div v-else class="togeter-text-zh">
                <span class="together-title">前往路线</span><br /><br />
                <p class="togeter-text-zh">
               创新斗室地理位置优越，交通便利。从市中心商业区启程，行车15分钟，或是乘大巴30分钟即可抵达，而且与深圳比邻而居，行车只需30分钟。同时创新斗室离大学和沙田港铁站仅一步之遥，位于东铁线上，租户也可以在港铁站乘香港科技园职员穿梭巴士前往。
               </p>
            </div>
            <img v-if="1 === imgIndex" :src="pngAboutHkstp1" class="togeter-text-background togeter-zh-background" alt="">
        </div>
         
    </a-col>
</a-row>
    <div class="map_tagether">
        <div class="map_tageher_header_border"></div>
        <img :src="mapTagetherPNG" @click="jumpUrls"/>
        <div class="mobile-map-text">
            <span class="together-title">前往路线 </span><br /><br />
            <p class="togeter-text-zh">
           创新斗室地理位置优越，交通便利。从市中心商业区启程，行车15分钟，或是乘大巴30分钟即可抵达，而且与深圳比邻而居，行车只需30分钟。同时创新斗室离大学和沙田港铁站仅一步之遥，位于东铁线上，租户也可以在港铁站乘香港科技园职员穿梭巴士前往。
           </p>
        </div>
    </div>
</div>

</template>

<script>
import global from '../../global'
export default {
    name: 'Together',
    data() {
        return {
            pngLeftWhite: require('../../assets/img/left_white.png'),
            pngRightWhite: require('../../assets/img/right_white.png'),
            pngLeftBlack: require('../../assets/img/left_black.png'),
            pngRightBlack: require('../../assets/img/right_black.png'),
            pngAboutHkstp: require('../../assets/img/about_hkstp.png'),
            pngAboutHkstp1: require('../../assets/img/about_hkstp1.png'),
            pngTheNeighBorhood1: require('../../assets/img/the_neighborhood.png?t=333'),
            pngTheNeighBorhood2: require('../../assets/img/the_neighborhood_mobile.png?t=333'),
            pngGettingHere: require('../../assets/img/getting_here.png'),
            pngRightBack:require('../../assets/img/right_black.png'),
            mapTagetherPNG:require('../../assets/img/monnery_map.jpg'), 
            imgIndex: 1,
            pngImg: '',
            pngLeftTextStyle: {
                color: 'white'
            },
            pngRightTextStyle: {
                color: 'white'
            },
            pngLeftText: '',
            pngRightText: '',
            pngLeft: '',
            pngRight: '',
            imageIndex : 0,
            clientWidth:0,
            pngTheNeighBorhood:null,
            timer:false,
            screenWidths:0,
        }
    },
    methods: {
        next_info() {
            this.imgIndex++;
            if (global.getClientWidth() <= global.mobileWidth){
                if (this.imgIndex > 2) this.imgIndex = 1
            }else{
                if (this.imgIndex > 3) this.imgIndex = 1
            }
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '前往路线'
                    this.pngRightText = '社区环境'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '寻找我们'
                    this.pngRightText = '前往路线'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftBlack
                    this.pngRight = this.pngRightBlack
                    this.pngLeftText = '社区环境'
                    this.pngRightText = '寻找我们'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black',
                    }
                    break
            }
        },
        jumpUrl(){
           if(this.imgIndex == 3){
              window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");           
           }
        },
        jumpUrls(){
            window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");  
        },
        up_info() {
            this.imgIndex--
            if (this.imgIndex === 0) this.imgIndex = 3
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '前往路线'
                    this.pngRightText = '社区环境'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '寻找我们'
                    this.pngRightText = '前往路线'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '社区环境'
                    this.pngRightText = '寻找我们'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black'
                    }
                    break
            }
        }
    },
    mounted() {
        var that = this;
        this.imgIndex = 1
        this.pngImg = this.pngAboutHkstp
        this.pngLeft = this.pngLeftWhite
        this.pngRight = this.pngRightWhite
        this.pngLeftText = '前往路线'
        this.pngRightText = '社区环境'
        this.pngLeftTextStyle = {
            color: 'white'
        }
        this.pngRightTextStyle = {
            color: 'white'
        }
        this.$nextTick(function(){
            that.clientWidth = global.getClientWidth();
       })
       window.addEventListener("resize", function () {
            that.screenWidths = global.getClientWidth();
        });
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.screenWidths = _this.clientWidth = global.getClientWidth();
                      if(_this.screenWidths <= global.mobileWidth){
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood2
                      }else{
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood1
                      }
                      if(_this.imgIndex == 2){
                        _this.pngImg = _this.pngTheNeighBorhood
                      }

                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },

}
</script>

<style lang="less">

</style>
