<template>
<div id="Home" class="ping_fangHK_regular home-cn">
    <a-row>
        <a-col class="nav">
            <Nav></Nav>
            <Chatbot></Chatbot>
        </a-col>
        <a-col class="header_animation" v-if="1 == isVideo">
            <div >
                <video autoplay="autoplay" muted="muted" loop="loop" width="100%" height="100%">
                  <source :src="videoUrl" type="video/mp4">
                  <source :src="videoUrl" type="video/ogg">
                您的浏览器不支持Video标签。
                </video>
                <div class="video-screen"></div>
            </div>
        </a-col>
        <a-col id="innocell" class="header_title header_title_zh" >
    
             <p >
                <span style="position:relative;">
                    <span style="position:relative;">凝聚<img :src="gifBlueUnderline" alt="" style="top:100%;" class="blurundeerline"></span>创科社群<i style="display: inline-block;width: 20px;"></i><span style="position:relative;">构建<img :src="gifBlueUnderline2" alt="" style="top:100%;" class="blurundeerline"></span><span style="position:relative;">创新社区
                    <img style="position:absolute;width:50px;top: -40px;right: -20%;" id="mobile-gifBlueExclaimation" :src="gifBlueExclaimation" alt="" >
                    </span>
                    
                </span>
                
            </p>
        </a-col>
        <a-col class="header_animation" v-if="2 == isVideo">
           <div>
             <img :src="gifHeaderAnimation" alt="header_animation">
           </div>
        </a-col>
        <a-col class="intro_card mian">
            <div class="intro_card_text intro_card_text_zh">
                <div class="intro-item intro-item01 intro-active">
                    <div class="intro-item-content">
                        创新斗室是专为<a href="https://www.hkstp.org" target="_blank" style="text-decoration:underline"><b>香港科学园</b></a>创科人才建造的智能生活及协作空间。
                        <br/>
                        <br/>
                        大楼内的空间兼具<b>功能</b>和<b>美感</b>，旨在营造一个促进创科人才协作交流，激发创意思维的社区。
                       
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item01')" class="click-btn" data-aid="intro-item02" alt=""></div>
                </div>
                <div class="intro-item intro-item02">
                    <div class="intro-item-content">
                        创新斗室打破既有生活框架，透过共享空间及智能生活科技，加强租户<b>互动</b>及<b>协作</b>，营造真正社区连系，让志同道合的创科人才交流，随时撞击出新可能。
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item02')" class="click-btn" data-aid="intro-item01" alt=""></div>
                </div>
            </div>
            <img :src="gitIntroCard" class="intro_card_img" width="100%" alt="">
        </a-col>
        <a-col class="mian">
            <CoCarousel></CoCarousel>
        </a-col>
        <a-col id="room" class="mian">
            <SmartLiving></SmartLiving>
        </a-col> 
         <a-col class="mian">
           
            <a-col class="smart_end_title smart_end_subtitle ">
                <p >
                    凝聚创科<span style="position: relative;">社群，
                     <img :src="green_squiggle_animation" style="width:100%;position: absolute;left:-15px;top:25px;z-index:-1;"/>
                    </span>
                    让<span style="position: relative;">互动协作</span>打破框架
                    <br />
                    实践崭新<span style="position: relative;">理念
                    <img :src="yellowzigzag" style="width:120%;position: absolute;left:-5px;top:30px;z-index:-1;"/>
                    </span>
                   
                </p>
            </a-col>
        </a-col> 
        <a-col class="spaces mian" id="spaces" style="position: relative;">
            <img :src="pngmobileSpaces" width="100%" class="pcMoblieBg" alt="">
            <a-row class="spaces-text text-left spaces-text-zh">   
                <div class="spaces-head-text">
                    <div class="spaceStep1">
                        <a-col>
                            <span class="space_title" style="position: relative;">
                            创新场所
                            <img class="spaces-title-box" :src="gifYellowBox" alt="" >
                            </span>
                        </a-col>
                        <a-col>
                            <div class="mtop25">
                                工余时间，当然要暂时卸下严谨一面，放松及与好友相聚联谊！创新斗室早已为你准备再次点燃想像力及创意的空间。除了让你早晚「充电」的房间，这里拥有约3万平方呎的公共空间，供你工作、生活、娱乐及社交，随时与各地创科人才交流互动，共同享受全面的丰富生活。
                            </div>

                        </a-col>
                    </div>
                    <div class="spaceStep2">
                        <a-col>
                            <div class="mtop25">
                            为租户提供健康生活设施及鼓励想法交流、联手协作是创新斗室的使命。我们旨在助租户平衡私人及互动空间，让你专注工作后，能够专心休憩。创新斗室的空间都经过精心规划，除了娱乐场区，更设有让租户便于交流知识的区域。无论工作或休息，只要你想，都可随时满足所需。
                            </div>
                        </a-col>
                        <a-col>
                            <div class="mtop25">
                           当你到达创新斗室，便开始体验我们独特且充满活力的创科文化、多功能设施、协作社群和与智能服务。我们采用现代规划方式建造这所大楼，让创科人才专注工作与生活，建构深层社区连结，让互动打破框界限制，一同创造价值，构筑崭新理念。
                            </div>
                        </a-col>
                        <div class="space_bottom_img"><img :src="spaceBottomImg" /></div>
                    </div>
                </div>
                <div class="spaceStep3">
                    <a-col :span="24"><br /></a-col>
                    <a-col :span="24" >
                        <a-row>
                            <a-col :span="12">
                                <h4><i>工作区域</i></h4>
                                <p><font>•</font><span>会议室</span></p>
                                <p><font>•</font><span>办公桌</span></p>
                                <p><font>•</font><span>多功能活动室</span></p>
                                <p><font>•</font><span>演讲厅</span></p>
                            </a-col>
                            <a-col :span="12">
                               <h4><i>娱乐区域</i></h4>
                                <p><font>•</font><span>互动游戏空间</span></p>
                                <p><font>•</font><span>音乐室</span></p>
                                <p><font>•</font><span>派对场地</span></p>
                                <p><font>•</font><span>阅读空间</span></p>
                                <p><font>•</font><span>酒吧及烧烤区</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24"><br /></a-col>
                    <a-col :span="24" class="space-footer-text2">
                        <a-row>
                            <a-col :span="12" class="space_bottom_text">
                                <h4><i>休闲区域</i></h4>
                                <p><font>•</font><span>绿化空间</span></p>
                                <p><font>•</font><span>户内及户外绿色社交空间</span></p>
                                <p><font>•</font><span>Crossfit空间</span></p>
                                <p><font>•</font><span>健身中心</span></p>
                            </a-col>
                            <a-col :span="12">
                                <h4><i>基本区域</i></h4>
                                <p><font>•</font><span>智能自动贩卖机</span></p>
                                <p><font>•</font><span>小型市场</span></p>
                                <p><font>•</font><span>电子贮物柜</span></p>
                                <p><font>•</font><span>公共厨房</span></p>
                                <p><font>•</font><span>智能自助洗衣及干衣服务</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    
                    
                </div>
            </a-row>
            <div class="space_step_btn1"  data-aid="2"><b>{{spaceBtnText}} ＞ </b></div>
            <div class="pcSpaceBg">
                <img :src="pngSpaces" width="100%"  alt="">
            
            </div>
            
        </a-col>
        <a-col class="mian">
            <div class="smart_end_title space_end_title ">
                <span >共享社区启迪 <br/>— 创新。
                <img :src="blueArrow" style="position: absolute;width:100px;top:-80px;left:45%;margin-left: -50px;"/>
            </span>
            </div>
        </a-col>
        <a-col class="community mian" id="community">
            <a-col><img :src="pngCommunityTop" width="100%" alt="" class="pngCommunitytop"></a-col>
            <a-row class="community-text community-text-zh">
                <a-col class="community-title">社区互动<img :src="gifBlueSmile" class="community-smale" alt=""></a-col>
                <a-col>
                    只有工作，没有娱乐？在创新斗室不可能出现！创新不是在象牙塔内闭门造车，而是令身心放松，回归善于观察的状态，让轶事激发灵感，触发创新理念。因此，除了推广协作创新及促进知识交流的活动外，我们更会定期举办休闲社交活动，助你休息放空，为身心充电。密切留意创新斗室的活动消息！<br /><br />
                   我们持续追求创新和卓越。创新斗室社区由我们精心策划及挑选，创造适合创科人才实践理念、改变世界的生态圈。这里不仅是休憩之所，更是想法合成流转的社区，旨在助人才奋力前进、创造影响及推动突破的空间。
                </a-col>
            </a-row>
            <img :src="pngCommunity" width="100%" alt="" class="pngCommunity">
        </a-col>
        <a-col class="mian">
            <a-col class="together text-right">
                <a-row>
                    <a-col :span="14"></a-col>
                    <a-col :span="10" class="together-title">
                        <div class="together-text text-center together-title-zh" style="margin-left: -100px;">
                            携手协作，走得更远<br />
                           人多、更好办事
                        </div>
                        <img :src="gitCircled" width="90%" alt="" class="together_title_gif">
                    </a-col>
                </a-row>
            </a-col>
        </a-col>
         <a-col class="mian">
            <Together></Together>
        </a-col>
        <a-col class="touch text-left" style="" id="touch">
            <a-row>
                <div class="mian">
                <a-col class="touch-title">
                    联络<span></span>我们 <br />
                </a-col>
                </div>
                <a-col class="touch-content">
                    <div class="mian" style="padding-left:0;">
                        <a-col :span="16">
                        我们将於二零二一年第一季开始接受香港科技园伙伴公司的申请。
                          <br/>如有查询，请电邮至 <a style="text-decoration: underline;color:#000;" href="mailto:innocell.general@hkstp.org">innocell.general@hkstp.org</a>
                        </a-col>
                        <div style="clear: both;"></div>
                    </div>
                </a-col>
                <a-col class="touch-footer mian">
                    香港新界大埔创新路1号
                </a-col>
                <div class="mian m-touch-img" style=""> 
                    <a-col class="touch-img ">
                        <img :src="pngMan" alt="">
                    </a-col>
                </div>
            </a-row>
        </a-col>
    </a-row>
    <a-back-top />
    <Dialog></Dialog>
</div>
</template>

<script>
import Nav from '@/components/zh-cn/Nav'
import Chatbot from '@/components/Chatbot'
import CoCarousel from '@/components/zh-cn/CoCarousel'
import SmartLiving from '@/components/zh-cn/SmartLiving'
import Together from '@/components/zh-cn/Together'
import Dialog from '@/components/Dialog'
import $ from 'jquery'
import global from '../global'
export default {
    name: 'Home',
    components: {
        Nav,
        Chatbot,
        CoCarousel,
        SmartLiving,
        Together,
        Dialog
    },
    data() {
        return {
            gifHeaderAnimation: require('../assets/img/gif/header_animation.gif'),
            gifBlueUnderline: require('../assets/img/cn/-cbGxqvw.png'),
            gifBlueUnderline2:require('../assets/img/cn/pgtWMFdA.png'),
            gifBlueExclaimation: require('../assets/img/gif/blue_exclaimation_gif.gif'),
            gifBlueSmile: require('../assets/img/gif/blue_smile_animation.gif'),
            gifYellowBox: require('../assets/img/cn/hs2ujsnA.png'),
            gitIntroCard: require('../assets/img/cn/Z893afjA.png'),
            gitCircled: require('../assets/img/gif/circled.gif'),
            pngSpaces: require('../assets/img/spaces_background.png'),
            pngmobileSpaces:require('../assets/img/spacespcMoblieBg01.png'),
            pngCommunity: require('../assets/img/community_background.png'),
            pngMan: require('../assets/img/man.png'),
            blueArrow:require('../assets/img/gif/blue_arrow_gif.gif'),
            vimeoURL:'https://vimeo.com/468811485/3a7edbd5de',
            
            green_squiggle_animation:require('../assets/img/gif/yellowline.gif'),
            green_squiggle_animation2:require('../assets/img/gif/yellowline2.gif'),
            yellowzigzag:require('../assets/img/gif/yellowzigzag.gif'),

            videoUrl:require('../assets/img/header_animation.mp4'),
            spaceBottomImg:require('../assets/img/space_bottom_img.png'),
            gitIntrRigthBtn:require('../assets/img/right_black.png'),
            clientWidth:0,
            pngCommunityTop:require('../assets/img/community-top.png'),
            screenWidth:0,
            timer:false,
            screenWidths:0,
            isLoading:null,
            isVideo:2,
            SpaceBoxHeight:0,
            aid:1,
            spaceBtnText:'阅读更多',
            isClick:false,
        }
    },
    mounted() {
        var that = this
        this.$cookies.set('language','zh_CN')
        that.clientWidth = global.getClientWidth();
        this.$nextTick(function(){
            let h = $(".intro_card").find('.intro-item').height();
            $(".intro_card_text").css({height:(h+120)+"px"})
            var items = document.getElementsByClassName("intro-item");
            if(items.length > 0){
                var i = 0;
                Array.prototype.forEach.call(items, function (element) {
                    if(i == 0){
                        element.style.left= "0px";
                        element.classList.add('intro-active');
                    }else{
                        element.style.left = that.clientWidth+"px";
                    }
                    i++;
                })
                
            }
            
            // 点击
            $("body").on("click",".space_step_btn1",function(){
                that.aid++;
                if(that.aid > 3){
                    that.aid = 1;
                }
                var aid = that.aid;
                if(global.getScreenWidth() <= global.mobileWidth && (aid == 2 || aid == 3)){

                    $("#spaces").find(".spaces-text").css({"height":that.SpaceBoxHeight+"px"}) 
                }else{
                    $("#spaces").find(".spaces-text").css({"height":"auto"})
                }
                
                if(aid == 1){
                    $(".spaceStep3").hide();
                    $(".spaceStep1").show();
                    $("#spaces").removeClass("spacesHover");
                    that.spaceBtnText = "阅读更多";
                }else if(aid == 3){
                     $(".spaceStep2").hide();
                     $(".spaceStep3").show();
                     that.spaceBtnText = "创新场所";
                }else{
                    $(".spaceStep2").show();
                    $(".spaceStep1").hide();
                    $("#spaces").addClass("spacesHover");
                    that.spaceBtnText = "单击以查看不同的区域";
                }
            })
        })
        if(that.clientWidth <= 768){
            this.isVideo = 2;
        }else{
            this.isVideo = 1;
        }
         window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
            if(that.screenWidths == undefined){
                return ;
            }
            if(that.screenWidths <= 768){
                that.isVideo = 2;
            }else{
                that.isVideo = 1;
            }
        });
         window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                if(global.getScreenWidth() <= global.mobileWidth){
                    $(".spaceStep1").show();
                    $(".spaceStep2,.spaceStep3").hide();
                    $("#spaces").removeClass("spacesHover");
                    setTimeout(function(){
                        that.SpaceBoxHeight = $("#spaces").height()-72;
                    },50)
                 }else{
                    $("#spaces").removeClass("spacesHover");
                    $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                 }
            },100)
        }, false)
        // 手机点击
        $(document).on('touchstart','.click-btn',function(){
           $(this).css({transform:"rotate(10deg)"})
           var that = this;
           setTimeout(function(){
             $(that).css({transform:"rotate(0deg)"})
           },200)
        })
    },
    methods: {
        introRightBtn(domeId){
            if(this.isClick){
                return ;
            }
            this.isClick = true;
            this.clientWidth = global.getClientWidth();
            $('.intro-item').removeClass("intro-active")
            console.log(domeId)
            if('intro-item02' == domeId){
                $('.intro-item02').css({"left":(this.clientWidth)+"px"})
                $(".intro-item01").animate({"left":"0px"},'normal');
                $(".intro-item01").addClass("intro-active")
            }else{
                $('.intro-item01').css({"left":(this.clientWidth)+"px"})
                $(".intro-item02").animate({"left":"0px"},'normal');
                $(".intro-item02").addClass("intro-active")
            }
           this.isClick = false;
        },
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  this.screenWidths = newVal;
                  
                  let h = $(".intro_card").find('.intro-item').height();
                  $(".intro_card_text").css({height:(h+120)+"px"}) 
                  var _this = this
                  $(".intro-item").css({left:this.clientWidth+"px"})
                  $(".intro-item.intro-active").css({left:"0px"})
                  _this.timer = true;
                  setTimeout(function(){
                     _this.clientWidth = global.getClientWidth();      
                     if(global.getScreenWidth() <= global.mobileWidth){
                        $(".spaceStep1").show();
                        $(".spaceStep2,.spaceStep3").hide();
                        $("#spaces").removeClass("spacesHover");
                        setTimeout(function(){
                            _this.SpaceBoxHeight = $("#spaces").height()-72;
                        },50)
                     }else{
                        $("#spaces").removeClass("spacesHover");
                        $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                     }
                     $("#spaces").find(".spaces-text").css({"height":"auto"})
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },

}
</script>

<style lang="less">

</style>
