<template>
<div id="SmartLiving" class="smart-living-list">
    <div class="smart text-left" v-if="1 === imgIndex">
        <div class="smart-living">
            <a-row>
                <a-col>  灯光 </a-col>
                <a-col>
                    <a-switch :checked="statusLight" @change="change_status('light')" />
                </a-col>
                <a-col>电视</a-col>
                <a-col>
                    <a-switch :checked="statusTv" @change="change_status('tv')" />
                </a-col>
                <a-col>冷气</a-col>
                <a-col>
                    <a-switch :checked="statusAirCon" @change="change_status('aircon')" />
                </a-col>
            </a-row>
        </div>
        <div class="smart-background"></div>
    </div>
    <div class="mbackground-Img"><img :src="backgroundImg" alt=""></div>
    <div class="smart-footer text-left">
        <a-row v-if="1 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right">
                    <a-col :span="21" class="smart-subtitme">
                        <div class="smart-btn-pc">
                            <p style="font-family: 'THRONE VECTOR';margin-bottom:0;">需要宁静独处时间？</p>
                            <p style="font-family: 'THRONE VECTOR';">快查看我们的房间！</p>
                        </div>
                        <div class="smart-btn-m">
                            <p style="margin-bottom:0;">看我们的房间</p>
                        </div>
                    </a-col>
                    <a-col class="smart-top-btn" :span="3">
                            <div class="smart-btn-pc">
                                <img :src="pngRight" class="click-btn" alt="" @click="next_info">
                            </div>
                            <div class="smart-btn-m">
                                <img :src="pngRightM" class="click-btn" style="width:30px" alt="" @click="next_info">
                            </div>
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    智能起居
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-5px;right:-25px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10" class="smart_text_zh">
                        <div class="smart-left-content smart_text_zh"> 
                            创新斗室的智能生活系统聪明得（几乎和你一样）令人惊喜。这里配备最先进的智能技术，提供点对点解决方案，提升租户生活体验，同时加快我们运营效率，更迅速满足你的需要。我们揉合一系列创新科技，打造舒适惬意的一站式智能生活，助你轻松解决日常事项，尽情享受生活。

                        </div>
                        <div class="smart_seeto_more">点击查看智能生活功能...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                            <div class="PRODUCTS-002">
                                <p style="background:none;"><i>智能生活</i></p>
                                <p>自助入住登记柜台</p>
                                <p>人面识别系统</p>
                                <p>手机触控智能门锁</p>
                                <p>电子支付系统</p>
                            </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="PRODUCTS-002">
                                <p style="background:none;"><i>便利生活</i></p>
                                <p>租户限定的手机程序(InnoCell Living app)</p>
                                <p>私人Google管家</p>
                                <p>多国语言聊天机器人</p>
                                <p>先进通讯网络</p>
                                <p>智能家居系统</p>
                                <p>室内空气监测系统</p>
                                <p>24小时保安</p>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="2 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                        <img :src="pngLeft" class="click-btn "  alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                        <img :src="pngRight" class="click-btn"  alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE POWERHUB
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content smart_text_zh"> 
                            团队风格，由你定义。迫不及待把你的理念成形？借助「团」倍增团队效率，迈向成功吧！ THE POWERHUB是个创新共享工作及生活空间，由8间连接共用工作空间的独立房间组成，方便你和团队随时把概念迅速实践。
                        </div>
                        <div class="smart_seeto_more">点击查看智能生活功能...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                      
                            <div class="text-color-green">
                                <p class="text-1">8间183平方呎的私人房间</p>
                                <p class="text-6">700+呎私人共用工作空间</p>
                                <p class="text-2">每房均有一張单人床</p>
                                <p class="text-3">适合团队</p>
                                <p class="text-4">家具齐备</p>
                                <p class="text-5">WiFi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">创新斗室智能生活系统</p>

                                <p class="text-7">人面识别门锁</p>
                                </div>

                        </a-col>
                        <a-col :span="7">
                             <div class="text-wile">
                                <p class="text-8">智能电视</p>
                                <p class="text-9">可预约清洁服务</p>
                                <p class="text-10">私人浴室</p>
                                <p class="text-11">私人茶水间、电磁炉冰箱及餐具</p>
                                <p class="text-12">床单和毛巾在入住时提供</p>
                                <p class="text-13">租金包括杂费(空调费除外)</p>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="3 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                        <img :src="pngLeft" class="click-btn "  alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                        <img :src="pngRight" class="click-btn"  alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE SOLO
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content smart_text_zh"> 
                            我们都需要真正私人空间，比如可以大展歌喉的洗澡时间及舒适放松的睡衣时间。 THE SOLO适合需要独处空间的你，在专注创新的一天后，尽情放松及「充电」，第二天再精神奕奕发挥所长。
                        </div>
                        <div class="smart_seeto_more">点击查看智能生活功能...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                      
                            <div class="text-color-green">
                                <p class="text-1">248平方呎</p>
                                <p class="text-2">1张双人床(最多2人)</p>
                                <p class="text-3">适合个人</p>
                                <p class="text-4">家具齐备</p>
                                <p class="text-5">WiFi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">创新斗室智能生活系统</p>

                                <p class="text-7">人面识别门锁</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                             <div class="text-wile">
                                <p class="text-8">智能电视</p>
                                <p class="text-9">可预约清洁服务</p>
                                <p class="text-10">私人浴室</p>
                                <p class="text-11">私人茶水间、电磁炉冰箱及餐具</p>
                                <p class="text-12">床单和毛巾在入住时提供</p>
                                <p class="text-13">租金包括杂费(空调费除外)</p>
                            </div>
                        </a-col>
                     </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="4 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                        <img :src="pngLeft" class="click-btn "  alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                        <img :src="pngRight" class="click-btn"  alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE TWIN
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content smart_text_zh"> 
                            与你的朋友或同事在THE TWIN共享生活，分享宽敞的起居空间，同时保留惬意的私人区域。说真的，室友能让生活增添「双」倍趣味和欢乐呢。
                        </div>
                        <div class="smart_seeto_more">點擊查看智能生活功能...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                       
                            <div class="text-color-green">
                                <p class="text-1">248平方呎</p>
                                <p class="text-2">2张独立单人床(最多2人)</p>
                                <p class="text-3">适合个人</p>
                                <p class="text-4">家具齐备</p>
                                <p class="text-8">房内设有保险箱</p>
                                <p class="text-5">Wifi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">创新斗室智能生活系统</p>

                                <p class="text-7">人面识别门锁</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="text-wile">
                                <p class="text-8">智能电视</p>
                                <p class="text-9">可预约清洁服务</p>
                                <p class="text-10">私人浴室</p>
                                <p class="text-11">私人茶水间、电磁炉冰箱及餐具</p>
                                <p class="text-12">床单和毛巾在入住时提供</p>
                                <p class="text-13">租金包括杂费(空调费除外)</p>
                            </div>
                        </a-col>
                     </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="5 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                        <img :src="pngLeft" class="click-btn "  alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                        <img :src="pngRight" class="click-btn"  alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE SUITE
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span>
                    
                    </a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content smart_text_zh"> 
                           家是动力来源，无论家人或同度创业高低的伙伴，一同在宽敞舒适的SUITE开展有伴的起居生活吧。 THE SUITE设有独立客厅及睡房，既可与亲友享受热闹共处的时光，又能保留个人恬适时刻。
                        </div>
                        <div class="smart_seeto_more">点击查看智能生活功能...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                      
                             <div class="text-color-green">                                
                                <p class="text-1">496平方呎</p>
                                <p class="text-2">1张双人床及2张沙发床</p>
                                <p class="text-4">家具齐备</p>
                                <p class="text-8">房内设有保险箱</p>
                                <p class="text-5">WiFi-6</p>

                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">创新斗室智能生活系统</p>

                                <p class="text-7">人面识别门锁</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="text-wile">
                                <div class="text-wile">
                                <p class="text-8">智能电视</p>
                                <p class="text-9">可预约清洁服务</p>
                                <p class="text-10">私人浴室</p>
                                <p class="text-11">私人茶水间、电磁炉冰箱及餐具</p>
                                <p class="text-12">床单和毛巾在入住时提供</p>
                                <p class="text-13">租金包括杂费(空调费除外)</p>
                            </div>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</div>
</template>

<script>
import jq from 'jquery'
import global from '../../global'
export default {
    name: "SmartLiving",
    data() {
        return {
            pngSmart: require('../../assets/img/gif/smart_living/smartliving_default_1015.png'),
            pngSmartAc: require('../../assets/img/gif/smart_living/smartliving_ac_1015.png'),
            pngSmartLightoff: require('../../assets/img/gif/smart_living/smartliving_lightoff_1015.png'),
            gifSmartTv: require('../../assets/img/gif/smart_living/smart_tv_animation_1015.gif'),
            pngRight: require('../../assets/img/right.png'),
            pngSmart1: require('../../assets/img/smart1.png'),
            pngSmart2: require('../../assets/img/smart2.png'),
            pngSmart3: require('../../assets/img/smart3.png'),
            pngSmart4: require('../../assets/img/smart4.png'),
            pngRightM: require('../../assets/img/right_white2.png'),
            pngLeft:require('../../assets/img/left.png'),
            yelowImageUrlExclaimation:require('../../assets/img/gif/Green Exclaimation.png'),
            pngSmartMobile1: require('../../assets/img/mobile/mobilesmart.gif'),
            pngSmartMobile2: require('../../assets/img/mobile/PowerHubImage.png'),
            pngSmartMobile3: require('../../assets/img/mobile/SoloImage.png'),
            pngSmartMobile4: require('../../assets/img/mobile/SuiteImage.png'),
            pngSmartMobile5: require('../../assets/img/mobile/TwinImage.png'),
            backgroundImg: '',
            imgIndex: 1,
            statusLight: false,
            statusTv: false,
            statusAirCon: false,
            clientWidth:0,
            timer:false,
            screenWidths:0
        }
    },
    methods: {
        change_status(name) {
            this.clientWidth = global.getClientWidth();
            switch (name) {
                case 'light':
                    this.backgroundImg = this.pngSmartLightoff
                    if(this.statusLight){
                        this.statusLight = false
                        this.statusTv = false
                        this.statusAirCon = false
                    }else{
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        this.statusLight = true
                    }
                    
                    break
                case 'tv':
                    this.backgroundImg = this.gifSmartTv
                    if(this.statusTv){
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        
                        this.statusTv = false
                        
                    }else{
                        this.statusLight = true
                        this.statusTv = true
                         this.statusAirCon = false
                    }
                    
                    break
                case 'aircon':
                    this.backgroundImg = this.pngSmartAc
                    if(this.statusAirCon){
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        this.statusAirCon = false
                    }else{
                         this.statusLight = true
                         this.statusAirCon = true
                         this.statusTv = false
                    }
                    
                    break
                default:
                    this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                    this.statusLight = true
                    this.statusTv = false
                    this.statusAirCon = false
                    break
            }
        },
        next_info() {
            this.imgIndex++;
            if (this.imgIndex > 5) this.imgIndex = 1
            jq(".smart_seeto_more").hide();
            this.clientWidth = global.getClientWidth();
            if(global.getScreenWidth() <= global.mobileWidth){
                jq(".footer-content-more").hide();
                jq(".smart_seeto_more").show();
            }
            switch (this.imgIndex) {
                case 1:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile1
                    }else{
                       this.backgroundImg = this.pngSmart
                    }
                    
                    break
                case 2:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile2
                    }else{
                       this.backgroundImg = this.pngSmart1
                    }
                    break
                case 3:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile3
                    }else{
                       this.backgroundImg = this.pngSmart2
                    }
                    break
                case 4:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile4
                    }else{
                       this.backgroundImg = this.pngSmart3
                    }
                    break
                case 5:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile5
                    }else{
                       this.backgroundImg = this.pngSmart4
                    }
                    break
            }
        },
        prex_info(){
            this.imgIndex--;
            if (this.imgIndex < 1) this.imgIndex = 5
            jq(".smart_seeto_more").hide();
            this.clientWidth = global.getClientWidth()
            if(global.getScreenWidth() <= global.mobileWidth){
                jq(".footer-content-more").hide();
                jq(".smart_seeto_more").show();
            }
            switch (this.imgIndex) {
                case 1:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile1
                    }else{
                       this.backgroundImg = this.pngSmart
                    }
                    
                    break
                case 2:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile2
                    }else{
                       this.backgroundImg = this.pngSmart1
                    }
                    break
                case 3:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile3
                    }else{
                       this.backgroundImg = this.pngSmart2
                    }
                    break
                case 4:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile4
                    }else{
                       this.backgroundImg = this.pngSmart3
                    }
                    break
                case 5:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile5
                    }else{
                       this.backgroundImg = this.pngSmart4
                    }
                    break
            }
        },
        seeToMore(){

        },
    },
    mounted() {
        var that = this;
        jq("body").on("click",".smart_seeto_more",function(){
            jq(this).parents(".smart-footer-content").find(".footer-content-more").show();
            jq(this).hide();
        })
        this.change_status('reset')
        this.clientWidth = global.getClientWidth();
         window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
        });
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  this.timer = true;
                  setTimeout(function(){
                        _this.clientWidth = _this.screenWidths = global.getClientWidth()
                        
                       if(global.getScreenWidth() <= global.mobileWidth){
                            _this.change_status('reset')
                            jq(".footer-content-more").hide();
                            jq(".smart_seeto_more").show();
                            switch (_this.imgIndex) {
                                case 1:
                                    _this.backgroundImg = _this.pngSmartMobile1
                                    break
                                case 2:
                                    _this.backgroundImg = _this.pngSmartMobile2
                                    break
                                case 3:
                                    _this.backgroundImg = _this.pngSmartMobile3
                                    break
                                case 4:
                                    _this.backgroundImg = _this.pngSmartMobile4
                                    break
                                case 5:
                                    _this.backgroundImg = _this.pngSmartMobile5
                                    break
                            }
                        }else{
                          _this.change_status('reset')
                          jq(".footer-content-more").show();
                          jq(".smart_seeto_more").hide();
                          switch (_this.imgIndex) {
                                case 1:
                                    _this.backgroundImg = _this.pngSmart
                                    break
                                case 2:
                                    _this.backgroundImg = _this.pngSmart1
                                    break
                                case 3:
                                    _this.backgroundImg = _this.pngSmart2
                                    break
                                case 4:
                                    _this.backgroundImg = _this.pngSmart3
                                    break
                                case 5:
                                    _this.backgroundImg = _this.pngSmart4
                                    break
                            }
                        }
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
}
</script>

<style lang="less">

</style>
