<template>
<div class="co-box">
<img class="" :src="gitUnmind" alt="" id="mobile-coImg">
    <div id="CoCarousel" :style="coImgHeight" class="co-carousel wrapper">
    
        <div class="co-carousel-item" :style="screenWidth" id="carousel-01">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="coImg" alt="" id="_coImg">
                    <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                        體驗創新型格<br/>的<span style="position:relative;">智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                    </h1>
                </div>
            </div>
            <div class="co-right-content co-background01">
                <div class="co-text co-content-view co-content-view-01 co-text_zh" :style="contentView01">
                    <h1>協作創新</h1>
                    <p>啟發思維，分享意念，協作創新。</p>
                    <br/>
                    <p>
                        你有好主意希望實踐？創新斗室正正能助你成真！在這裡，身邊圍繞的都是志同道合的創科人才，每天醒來時觸發創意，而且更有動力實踐。無論是在日間與同路人互相交流、攜手協作，或在夜間享受寧靜獨處的時光，你只要專注在重要的事情上，日常所需則安心交託給創新斗室。成為嶄新共享工作及生活空間的一員，快點整理行李，登記入住吧！
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-01" :style="contentView01"></div>
        </div>
        <div class="co-carousel-item" :style="screenWidth" id="carousel-02">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="gitInspire" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                        體驗創新型格<br/>的<span style="position:relative;">智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                    </h1>
                </div>
            </div>
            <div class="co-right-content co-background02">
                <div class="co-text co-content-view co-content-view-02 co-text_zh" :style="contentView01">
                    <h1>並肩共事</h1>
                    <p>準備好遇上你的下一個合作夥伴。</p>
                    <br/>
                    <p>
                        有好的團隊方能建構優秀的意念。與才幹洋溢的人共事，會讓你的工作更順暢、更趣味盎然！創新斗室的共享工作空間每天都助你連繫不同潛在合作夥伴。任何在創新斗室構思的意念都不渺小、天真或奇怪。在這裡，人才互相交流，讓你無所畏懼地嘗試及實踐，甚至敢於冒險打破現有框架，努力不懈地創建更多及更佳！
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-02 co-text_zh" :style="contentView01"></div>
        </div>
        
        <div class="co-carousel-item" :style="screenWidth" id="carousel-03" >
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="unmind_animation" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                        體驗創新型格<br/>的<span style="position:relative;">智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                    </h1>

                </div>
            </div>
             <div class="co-right-content co-background03 co-text_zh">
                <div class="co-text co-content-view co-content-view-03" :style="contentView01">
                    <h1>共活共享</h1>
                    <p>休閒憩息之所。</p>
                    <br/>
                    <p>
                        喜愛沐浴時深情演唱？需要不被打擾地自在休憩？我們深諳私人空間對你的重要。創新斗室的房間，讓你不論小休、睡眠或享受寧靜獨處時光，都能舒適寫意。每間房內均包括私人浴室、獨立小巧廚房及個人起居空間，涵蓋日常生活所需。充滿活力的公共區域及朝氣蓬勃的綠色戶外空間，則能讓你與其他租戶會面、工作，促進意念交流，並且共同消閒娛樂，放鬆身心。因此，創新斗室不只是生活場所，更是令你成長昇華的空間。
                    </p>
                    <div class="text-right right-btn" ><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-03" :style="contentView01"></div>
        </div>
    </div>
    <div class="co-carou-subtitle-mobile">
        <h1 class="co-img-h1">
            體驗創新型格<br/>的智能生活
        </h1>
    </div>
</div>

</template>

<script>
import jq from 'jquery'
import global from '../../global'
export default {
    name: 'CoCarousel',
    data() {
        return {
            pngRight: require('../../assets/img/right.png'),
            gitInnovate: require('../../assets/img/gif/co/innovate_animation.gif'),
            gitInspire: require('../../assets/img/gif/co/inspire_animation.gif'),
            gitUnmind: require('../../assets/img/gif/co/20201119174424.gif'),
            unmind_animation: require('../../assets/img/gif/co/unmind_animation.gif'),
            gitGreenSquiggle: require('../../assets/img/gif/co/green_squiggle_animation.gif'),
            coImg: '',
            coImgStyle: {},
            coTextStyle: {
                right: 0,
                top: '100px',
                zIndex: 2,
            },
            coBackgroundStyle: {
                top: '50px',
                right: '50px',
                zIndex: 998,
                background: '#fc33b6'
            },
            coImgHeight:{
                height:'700px',
                width:'1505px;'
            },
            coIndex: 1,
            contentView01:{
                height:'700px',
            },
            screenWidth:{
                width:"1000px;"
            },
            clientWidth:0,
            timer: false,
            screenWidths:0,
            isLoad : false,
        }
    },
    methods: {
        next_info() {
            var that = this;
            this.coIndex++;
            if (this.coIndex > 3) this.coIndex = 1
            switch (this.coIndex) {
                case 1:
                    this.coImg = this.gitInnovate
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                         var domH = parseInt(oImg.height);
                         var itemHeight = jq(".co-carousel-item").height();
                         var h = domH+200
                         if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-01").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                         
                         that.coImgHeight.height = h+"px";
                         jq("#carousel-01").animate({"left":"0px"},'normal')
                         jq("#carousel-03").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-03").css({"left":that.clientWidth+"px"})
                         })
                         
                    }
                    break
                case 2:
                    this.coImg = this.gitInspire
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-02").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-02").animate({"left":"0px"},'normal')
                        jq("#carousel-01").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-01").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    break
                case 3:
                    this.coImg = this.unmind_animation
                    var oImg = document.getElementById("_coImg");
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-03").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                            console.log("3N"+itemHeight)
                        }
                        
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-03").animate({"left":"0px"},'normal')
                        jq("#carousel-02").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-02").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    
                    break
            }
        },
     
        _loadImage(){
            const that = this;
            that.clientWidth = global.getClientWidth();
            that.screenWidth = {
                width:this.clientWidth+"px"
            }
            console.log("===Y="+global.getClientWidth())
            var oImg = document.getElementById("_coImg")
            var domW = that.clientWidth;
            that.coImgHeight.width = domW+"px";
            if(that.isLoad){
                setTimeout(function(){
                    var domH = parseInt(oImg.height);
                    var itemHeight = 0;
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                        h = itemHeight = parseInt(itemHeight) + 50;
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }

                },500)
            }else{
                oImg.onload = function(){
                    var domH = parseInt(oImg.height);
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                         h = itemHeight = parseInt(itemHeight) + 50;
                        console.log("N"+itemHeight)
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }
                    that.isLoad = true;
                    
                }
            }
            jq("#carousel-01").css({"left":"0px"})
            jq("#carousel-02").css({"left":that.clientWidth+"px"})
            jq("#carousel-03").css({"left":that.clientWidth+"px"})
        },
    },
    mounted() {
        const that = this;
        this.coImg = this.gitInnovate
        
        this.$nextTick(function(){
            that._loadImage()
       })
        window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
        });
        window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                that.screenWidths = global.getScreenWidth()
                that.clientWidth = global.getClientWidth();

                that.$nextTick(function(){
                     setTimeout(function(){that._loadImage()},1000)
                  }) 
                

            },100)
        }, false)
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.clientWidth = global.getClientWidth();
                      _this._loadImage()
                      console.log("==="+global.getClientWidth())
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
}
</script>
