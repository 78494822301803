<template>
<div class="co-box">
<img class="" :src="gitUnmind" alt="" id="mobile-coImg">
    <div id="CoCarousel" :style="coImgHeight" class="co-carousel wrapper">
    
        <div class="co-carousel-item" :style="screenWidth" id="carousel-01">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="coImg" alt="" id="_coImg">
                    <div class="co-carou-subtitle">
             
                        <h1 class="co-img-h1">
                           <span style="position:relative;"> 智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>刷新想像
                        </h1>
                    </div>
            </div>
            <div class="co-right-content co-background01" id="_textBox">
                <div class="co-text co-content-view co-content-view-01 co-text_zh"  :style="contentView01">
                    <h1>协作创新</h1>
                    <p>启发思维，分享理​​念，协作创新。</p>
                    <br/>
                    <p>
                        你有好主意希望实践？创新斗室能助你成真！在这里，身边围绕的都是志同道合的创科人才，每天醒来时触发创意，而且更有动力实践。无论是在日间与同路人互相交流、携手协作，或在夜间享受宁静独处的时光，你只需专注在重要的事情上，日常所需则安心交托创新斗室。成为崭新共享工作及生活空间的一员，快点整理行李，登记入住吧！
                    </p>
                    <div class="text-right right-btn" ><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-01" :style="contentView01"></div>
        </div>
        <div class="co-carousel-item" :style="screenWidth" id="carousel-02">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="gitInspire" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                           <span style="position:relative;"> 智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>刷新想像
                        </h1>
                </div>
            </div>
            <div class="co-right-content co-background02">
                <div class="co-text co-content-view co-content-view-02 co-text_zh" :style="contentView01">
                    <h1>并肩共事</h1>
                    <p>准备好遇上你的下一个合作伙伴。</p>
                    <br/>
                    <p>
                        有好的团队方能建构优秀的意念。与才干洋溢的人共事，让你的工作更顺畅、更趣味盎然！创新斗室的共享工作空间每天都助你连系不同潜在合作伙伴。任何在创新斗室构思的理念都不渺小、天真或奇怪。在这里，人才互相交流，让你无所畏惧地尝试和实践，甚至敢于冒险打破现存框架，不懈地创建更多及更佳！
                    </p>
                    <div class="text-right right-btn" ><img :src="pngRight"  class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-02" :style="contentView01"></div>
        </div>
        
        <div class="co-carousel-item" :style="screenWidth" id="carousel-03" >
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="unmind_animation" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                   <h1 class="co-img-h1">
                           <span style="position:relative;"> 智能生活<img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>刷新想像
                        </h1>
                </div>
            </div>
             <div class="co-right-content co-background03">
                <div class="co-text co-content-view co-content-view-03 co-text_zh" :style="contentView01">
                    <h1>共活共享</h1>
                    <p>休闲憩息之所。</p>
                    <br/>
                    <p>
                        喜爱洗澡时深情演唱？需要不被打扰地自在休憩？我们深谙私人空间对你的重要。创新斗室的住房，让你无论小休、睡眠或享受宁静独处时光，都能舒适写意。每间房内都包括私人浴室、独立小巧厨房及个人起居空间，涵盖日常所需。充满活力的公共区域及朝气蓬勃的绿色户外空间，则能让你与其他租户会面、工作，促进想法交流，并且共同休闲娱乐，放松身心。因此，创新斗室不只是生活场所，更是让你成长升华的空间。
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-03" :style="contentView01"></div>
        </div>
    </div>
    <div class="co-carou-subtitle-mobile">
        <h1 class="co-img-h1">
            智能生活刷新想像
        </h1>
    </div>
</div>

</template>
<script>
import jq from 'jquery'
import global from '../../global'
export default {
    name: 'CoCarousel',
    data() {
        return {
            pngRight: require('../../assets/img/right.png'),
            gitInnovate: require('../../assets/img/gif/co/innovate_animation.gif'),
            gitInspire: require('../../assets/img/gif/co/inspire_animation.gif'),
            gitUnmind: require('../../assets/img/gif/co/20201119174424.gif'),
            unmind_animation: require('../../assets/img/gif/co/unmind_animation.gif'),
            gitGreenSquiggle: require('../../assets/img/gif/co/green_squiggle_animation.gif'),
            coImg: '',
            coImgStyle: {},
            coTextStyle: {
                right: 0,
                top: '100px',
                zIndex: 2,
            },
            coBackgroundStyle: {
                top: '50px',
                right: '50px',
                zIndex: 998,
                background: '#fc33b6'
            },
            coImgHeight:{
                height:'700px',
                width:'1505px;'
            },
            coIndex: 1,
            contentView01:{
                height:'700px',
            },
            screenWidth:{
                width:"1000px;"
            },
            clientWidth:0,
            timer: false,
            screenWidths:0,
            isLoad : false,
        }
    },
    methods: {
        next_info() {
            var that = this;
            this.coIndex++;
            if (this.coIndex > 3) this.coIndex = 1
            switch (this.coIndex) {
                case 1:
                    this.coImg = this.gitInnovate
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                         var domH = parseInt(oImg.height);
                         var itemHeight = jq(".co-carousel-item").height();
                         var h = domH+200
                         if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-01").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                         
                         that.coImgHeight.height = h+"px";
                         jq("#carousel-01").animate({"left":"0px"},'normal')
                         jq("#carousel-03").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-03").css({"left":that.clientWidth+"px"})
                         })
                         
                    }
                    break
                case 2:
                    this.coImg = this.gitInspire
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-02").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-02").animate({"left":"0px"},'normal')
                        jq("#carousel-01").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-01").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    break
                case 3:
                    this.coImg = this.unmind_animation
                    var oImg = document.getElementById("_coImg");
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-03").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                            console.log("3N"+itemHeight)
                        }
                        
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-03").animate({"left":"0px"},'normal')
                        jq("#carousel-02").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-02").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    
                    break
            }
        },
     
        _loadImage(){
            const that = this;
            that.clientWidth = global.getClientWidth();
            that.screenWidth = {
                width:this.clientWidth+"px"
            }
            console.log("===Y="+global.getClientWidth())
            var oImg = document.getElementById("_coImg")
            var domW = that.clientWidth;
            that.coImgHeight.width = domW+"px";
            if(that.isLoad){
                setTimeout(function(){
                    var domH = parseInt(oImg.height);
                    var itemHeight = 0;
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                        h = itemHeight = parseInt(itemHeight) + 50;
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }

                },500)
            }else{
                oImg.onload = function(){
                    var domH = parseInt(oImg.height);
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                         h = itemHeight = parseInt(itemHeight) + 50;
                        console.log("N"+itemHeight)
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }
                    that.isLoad = true;
                    
                }
            }
            jq("#carousel-01").css({"left":"0px"})
            jq("#carousel-02").css({"left":that.clientWidth+"px"})
            jq("#carousel-03").css({"left":that.clientWidth+"px"})
        },
    },
    mounted() {
        const that = this;
        this.coImg = this.gitInnovate
        
        this.$nextTick(function(){
            that._loadImage()
       })
        window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
        });
        window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                that.screenWidths = global.getScreenWidth()
                that.clientWidth = global.getClientWidth();

                that.$nextTick(function(){
                     setTimeout(function(){that._loadImage()},1000)
                  }) 
                

            },100)
        }, false)
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.clientWidth = global.getClientWidth();
                      _this._loadImage()
                      console.log("==="+global.getClientWidth())
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
}
</script>

