<template>
<a-row id="Nav">
    <a-col :span="8" class="text-left logo">
        <span class="logo-box">
            <img :src="pngLogo" alt="">
            <a href="https://www.hkstp.org" target="_blank"></a>
        </span> 
        <div class="mobile-meun"></div>
    </a-col>
    
    <a-col :span="16" class="text-right mobile-mume-box">
        <ul class="nav-ul" id="nav-ul">
            <li class="active innocell">
                <a href="javascript:;" @click="tabContent('innocell')">關於</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='room'>
                <a href="javascript:;" @click="tabContent('room',this)">房間</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='spaces'>
                <a href="javascript:;" @click="tabContent('spaces',this)">設施</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='community'>
                <a href="javascript:;" @click="tabContent('community',this)">社區</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='touch'>
                <a href="javascript:;" @click="tabContent('touch',this)">聯絡</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='chatbot'>
                <a href="javascript:;" data-lang="zh_HK" @click="btnChatbot(this)">咨詢</a>
                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
             <li class='LOGIN-APPLY'>
                <a href="javascript:;" v-if="!isLoinged" data-lang="en" @click="tabContent('LOGIN-APPLY',this)">申請</a>
                <a href="javascript:;" v-if="isLoinged" data-lang="en" @click="$router.push({path: '/welcome'});">申請</a>    

                <img :src="pngLine" alt="" class="nav-unde-line">
            </li>
            <li class='lang-nav'>
                <a href="javascript:;" @click="$router.push({path: '/'});">EN</a>
                <a href="javascript:;" @click="$router.push({path: '/zh-CN'});" class="ping_fangHK_regular">简</a>
                <a href="javascript:;" class="atc ping_fangHK_regular">繁</a>
            </li>
        </ul>
    </a-col>
</a-row>
</template>

<script>
import jq from 'jquery'
import global from '../../global'
export default {
    name: 'NavCn',
    data() {
        return {
            pngLogo: require('../../assets/img/Logos.png'),
            pngLine: require('../../assets/img/nav_line.png'),
            isChatbot:false,
            isLoinged:false
        }
    },
    methods: {
        tabContent(docum){
            var documentId = document.querySelector("#"+docum);
            var lis = document.querySelectorAll(".nav-ul li").forEach(function(item){
                item.classList.remove('active');
            });
            document.querySelector("."+docum).classList.add('active');
            this.isChatbot = false;
            jq("#_chatbot").animate({height:"0"})
            // 弹出窗口
            if('LOGIN-APPLY' == docum){
                document.querySelector(".dialog-login").style.display="block";
                return false;
            }
            
            window.scrollTo({
                'top': documentId.offsetTop-110,
                'behavior': 'smooth'
            })
            var clientWidth = global.getClientWidth();
            if(clientWidth <= global.mobileWidth ){
                jq(".mobile-mume-box").removeClass("nume-open").hide();
            }
            this.isChatbot = false;
            jq("#_chatbot").animate({height:"0"})
        },
        btnChatbot(e){
            document.querySelector(".dialog-login").style.display="none";
            this.$cookies.set('language','en')
            var lis = document.querySelectorAll(".nav-ul li").forEach(function(item){
                item.classList.remove('active');
            });
            document.querySelector(".chatbot").classList.add('active');
            if(this.isChatbot){
                jq("#_chatbot").animate({height:"0"})
                this.isChatbot = false;
            }else{
                if(document.documentElement.clientHeight < 700){
                    jq("#_chatbot").animate({height:"450px"})
                }else{
                    jq("#_chatbot").animate({height:"600px"})
                }
                
                this.isChatbot = true;
            }
            var clientWidth = global.getClientWidth();
            if(clientWidth <= global.mobileWidth ){
                jq(".mobile-mume-box").removeClass("nume-open").hide();
            }
        }
    },
    mounted() {
        this.$nextTick(function(){
            if(this.$cookies.isKey('_TOKEN_') && this.$cookies.get('_TOKEN_') != null ){
                this.isLoinged = true;
            }
            jq(".mobile-meun").click(function(){
                if(jq(".mobile-mume-box").hasClass("nume-open")){
                    jq(".mobile-mume-box").removeClass("nume-open").slideUp();
                }else{
                    jq(".mobile-mume-box").addClass('nume-open').slideDown();
                }   
            })
        })
    }
}
</script>

<style lang="less">

</style>
