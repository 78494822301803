<template>
<div class="m-together">
<a-row id="Together" >
    <a-col>
        <img :src="pngImg" width="100%" alt="Test" @click="jumpUrl">
       <a-row class="togeter-button">
            <a-col :span="3" @click="up_info"><img :src="pngLeft" class="click-btn"  alt=""></a-col>
            <a-col :span="9" @click="up_info" :style="pngLeftTextStyle">{{pngLeftText}}</a-col>
            <a-col :span="9" @click="next_info" :style="pngRightTextStyle">{{pngRightText}}</a-col>
            <a-col :span="3" @click="next_info"><img :src="pngRight" class="click-btn" alt=""></a-col>
        </a-row>

        <div class="togeter-background">

        </div>
        <div class="togeter-text text-left">
            <a-col :span="12" @click="next_info" class="mobile-togeter-button"><img :src="pngRightBack" class="click-btn" alt=""></a-col>
            <p v-if="1 === imgIndex" class="togeter-text-zh">
                <span class="together-title">尋找我們 </span><br />
                <br />
                創新斗室位處於香港目前最大的科研基地香港科技園。香港有望成為亞洲下一個創新樞紐，香港科技園正為實現這個目標貢獻我們的力量。我們在具戰略意義的大灣區建立科技發展的大本營，致力造福世界。香港科技園是敢於實現夢想的創業家的理想孵化園地，又是連繫環球各行各業和市場商機的平台。不管你是創業家或是企業持份者，香港科技園都會為你提供所需的基礎設施、知識、協作機會和支援，通過創新及科技取得成功。

            </p>
            <p v-else-if="2 === imgIndex" class="togeter-text-zh">
                <span class="together-title">社區環境</span><br /><br />
               位處於寧靜美麗的吐露港，香港科技園為創新斗室提供<a style="text-decoration:underline;color:#000;" href="https://www.hkstp.org/our-community/park-amenities/" target="_blank" ><b>多元便利設施</b></a>，無論是多國美食、超級市場、銀行中心或健康服務，你都能便捷地在園區內找到你需要的服務。香港科技園會所更為租戶提供專屬服務，你可使用桑拿室、游泳池、健身中心及運動室，助你放鬆及卸下疲勞。
                <a-row class="toget-icon-boax">
                    <a-col :span="8">
                        <div class="subTime-img-text images001">
                            超級市場
                        </div>
                    </a-col>
                    <a-col :span="8">
                    <div class="subTime-img-text images004">會所</div>
                    </a-col>
                    <a-col :span="8">
                    
                    </a-col>

                    </a-row>
                    <a-row >
                        <a-col :span="8" >
                        <div class="subTime-img-text images003">醫療保健</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images002">銀行</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images005">餐廳</div>
                        </a-col>

                    </a-row>
            </p>
            <p v-else class="togeter-text-zh">
                <span class="together-title">前往路綫</span><br /><br />
               創新斗室地理位置便利，從市中心駕駛15分鐘或乘30分鐘巴士即可到達，與深圳邊境只距離半小時車程。同時創新斗室位於東鐵沿線，鄰近大學站及沙田站，租戶亦可在鄰近港鐵站選乘香港科技園職員穿梭巴士前往。
            </p>
            <img v-if="1 === imgIndex" :src="pngAboutHkstp1" class="togeter-text-background togeter-zh-background" alt="">
        </div>
         
    </a-col>
</a-row>
    <div class="map_tagether">
        <div class="map_tageher_header_border"></div>
        <img :src="mapTagetherPNG" @click="jumpUrls"/>
        <div class="mobile-map-text">
            <span class="together-title">前往路綫</span><br /><br />
           創新斗室地理位置便利，從市中心駕駛15分鐘或乘30分鐘巴士即可到達，與深圳邊境只距離半小時車程。同時創新斗室位於東鐵沿線，鄰近大學站及沙田站，租戶亦可在鄰近港鐵站選乘香港科技園職員穿梭巴士前往。
        </div>
    </div>
</div>

</template>

<script>
import global from '../../global'
export default {
    name: 'Together',
    data() {
        return {
            pngLeftWhite: require('../../assets/img/left_white.png'),
            pngRightWhite: require('../../assets/img/right_white.png'),
            pngLeftBlack: require('../../assets/img/left_black.png'),
            pngRightBlack: require('../../assets/img/right_black.png'),
            pngAboutHkstp: require('../../assets/img/about_hkstp.png'),
            pngAboutHkstp1: require('../../assets/img/about_hkstp1.png'),
            pngTheNeighBorhood1: require('../../assets/img/the_neighborhood.png?t=333'),
            pngTheNeighBorhood2: require('../../assets/img/the_neighborhood_mobile.png?t=333'),
            pngGettingHere: require('../../assets/img/getting_here.png'),
            pngRightBack:require('../../assets/img/right_black.png'),
            mapTagetherPNG:require('../../assets/img/monnery_map.jpg'), 
            imgIndex: 1,
            pngImg: '',
            pngLeftTextStyle: {
                color: 'white'
            },
            pngRightTextStyle: {
                color: 'white'
            },
            pngLeftText: '',
            pngRightText: '',
            pngLeft: '',
            pngRight: '',
            imageIndex : 0,
            clientWidth:0,
            pngTheNeighBorhood:null,
            timer:false,
            screenWidths:0,
        }
    },
    methods: {
        next_info() {
            this.imgIndex++;
            if (global.getClientWidth() <= global.mobileWidth){
                if (this.imgIndex > 2) this.imgIndex = 1
            }else{
                if (this.imgIndex > 3) this.imgIndex = 1
            }
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '前往路線'
                    this.pngRightText = '社區環境'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '尋找我們'
                    this.pngRightText = '前往路線'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftBlack
                    this.pngRight = this.pngRightBlack
                    this.pngLeftText = '社區環境'
                    this.pngRightText = '尋找我們'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black',
                    }
                    break
            }
        },
        jumpUrl(){
           if(this.imgIndex == 3){
              window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");           
           }
        },
        jumpUrls(){
           window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");        
        },
        up_info() {
            this.imgIndex--
            if (this.imgIndex === 0) this.imgIndex = 3
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '前往路線'
                    this.pngRightText = '社區環境'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '尋找我們'
                    this.pngRightText = '前往路線 '
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = '社區環境'
                    this.pngRightText = '尋找我們'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black'
                    }
                    break
            }
        }
    },
    mounted() {
        var that = this
        this.imgIndex = 1
        this.pngImg = this.pngAboutHkstp
        this.pngLeft = this.pngLeftWhite
        this.pngRight = this.pngRightWhite
        this.pngLeftText = '前往路線'
        this.pngRightText = '社區環境'
        this.pngLeftTextStyle = {
            color: 'white'
        }
        this.pngRightTextStyle = {
            color: 'white'
        }
         this.$nextTick(function(){
            that.clientWidth = global.getClientWidth();
       })
       window.addEventListener("resize", function () {
            that.screenWidths = global.getClientWidth();
        });
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.screenWidths = _this.clientWidth = global.getClientWidth();
                      if(_this.screenWidths <= global.mobileWidth){
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood2
                      }else{
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood1
                      }
                      if(_this.imgIndex == 2){
                        _this.pngImg = _this.pngTheNeighBorhood
                      }

                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },

}
</script>

<style lang="less">

</style>
