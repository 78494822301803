<template>
<div id="Home" class="ping_fangHK_regular home-cn">
    <a-row>
        <a-col class="nav">
            <Nav></Nav>
            <Chatbot></Chatbot>
        </a-col>
        <a-col class="header_animation" v-if="1 == isVideo">
            <div >
                <video autoplay="autoplay" muted="muted" loop="loop" width="100%" height="100%">
                  <source :src="videoUrl" type="video/mp4">
                  <source :src="videoUrl" type="video/ogg">
                您的浏览器不支持Video标签。
                </video>
                <div class="video-screen"></div>
            </div>
        </a-col>
        <a-col id="innocell" class="header_title header_title_zh" >
            <p>
                <span style="position:relative;">
                    <span style="position:relative;">凝聚<img :src="gifBlueUnderline" alt="" style="top:100%;" class="blurundeerline"></span>創科社群<i style="display: inline-block;width: 20px;"></i><span style="position:relative;">構建<img :src="gifBlueUnderline2" alt="" style="top:100%;" class="blurundeerline"></span><span style="position:relative;">創新社區
                    <img style="position:absolute;width:50px;top: -40px;right: -20%;" id="mobile-gifBlueExclaimation" :src="gifBlueExclaimation" alt="" >
                    </span>
                    
                </span>
            </p>
        </a-col>
        <a-col class="header_animation" v-if="2 == isVideo">
           <div>
             <img :src="gifHeaderAnimation" alt="header_animation">
           </div>
        </a-col>
        <a-col class="intro_card mian">
            <div class="intro_card_text intro_card_text_zh">
                <div class="intro-item intro-item01 intro-active ">
                    <div class="intro-item-content">
                        創新斗室是專為<a href="https://www.hkstp.org" target="_blank" style="text-decoration:underline"><b>香港科學園</b></a>創科人才建造的智能生活及協作空間。
                        <br/>
                        <br/>
                        大樓內的空間兼具<b>功能</b>和<b>美感</b>，旨在營造一個促進創科人才協作交流，激發創意思維的社區。
                       
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item01')" class="click-btn" data-aid="intro-item02" alt=""></div>
                </div>
                <div class="intro-item intro-item02">
                    <div class="intro-item-content">
                        創新斗室打破既有生活框架，透過共享空間及智能生活科技，加強租戶互動及協作，締造真正社區連繫，讓志同道合的創科人才交流，隨時撞擊出新可能。
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item02')" class="click-btn" data-aid="intro-item01" alt=""></div>
                </div>
            </div>
            <img :src="gitIntroCard" class="intro_card_img" width="100%" alt="">
        </a-col>
        <a-col class="mian">
            <CoCarousel></CoCarousel>
        </a-col>
        <a-col id="room" class="mian">
            <SmartLiving></SmartLiving>
        </a-col> 
        <a-col class="mian">
            <a-col class="smart_end_title smart_end_subtitle ">
                <p >
                    凝聚創科<span style="position: relative;">社群，
                     <img :src="green_squiggle_animation" style="width:100%;position: absolute;left:-15px;top:25px;z-index:-1;"/>
                    </span>
                    激發創意<span style="position: relative;">靈感　
                    <img :src="green_squiggle_animation2" style="width:140%;position: absolute;left:-10px;top:20px;z-index:-1;"/>
                    </span>
                    <br />
                    孕育創新<span style="position: relative;">意念　
                    <img :src="yellowzigzag" style="width:120%;position: absolute;left:-5px;top:30px;z-index:-1;"/>
                    </span>
                   
                </p>
            </a-col>
        </a-col>
        <a-col class="spaces mian" id="spaces" style="position: relative;">
            <img :src="pngmobileSpaces" width="100%" class="pcMoblieBg" alt="">
            <a-row class="spaces-text text-left spaces-text-zh">   
                <div class="spaces-head-text">
                    <div class="spaceStep1">
                        <a-col>
                            <span class="space_title" style="position: relative;">
                            創新場所
                            <img class="spaces-title-box" :src="gifYellowBox" alt="" >
                            </span>
                        </a-col>
                        <a-col>
                            <div class="mtop25">
                                工餘時間，當然要暫時卸下嚴謹一面，放鬆及與好友相聚聯誼！創新斗室早已為你準備再次燃點想像力及創意的空間。除了讓你早晚「充電」的房間，這裡擁有約3萬平方呎的公共空間，供你工作、生活、娛樂及社交，隨時與各地創科人才交流互動，同享全面豐富生活。
                            </div>

                        </a-col>
                    </div>
                    <div class="spaceStep2">
                        <a-col>
                            <div class="mtop25">
                            為租戶提供健康生活設施及鼓勵意念流轉、聯手協作是創新斗室的使命。我們旨在助租戶平衡私人及互動空間，讓你專注工作後，能夠專心休憩。創新斗室的間隔經過精心規劃，除了娛樂場區，更設有讓租戶便於交流知識的區域。無論工作或休息，只要你想，都可隨時滿足所需。

                            </div>
                        </a-col>
                        <a-col>
                            <div class="mtop25">
                           當你到達創新斗室，便開始體驗我們獨特且充滿活力的創科文化、多功能設施、協作社群和及智能服務。我們採用現代方式建築這所大樓，讓創科人才專注工作與生活，建構深層社區連結，讓互動打破框界限制，一同創造價值，共築嶄新意念。
                            </div>
                        </a-col>
                        <div class="space_bottom_img"><img :src="spaceBottomImg" /></div>
                    </div>
                </div>
                <div class="spaceStep3">
                    <a-col :span="24"><br /></a-col>
                    <a-col :span="24" >
                        <a-row>
                            <a-col :span="12">
                                <h4><i>工作區域</i></h4>
                                <p><font>•</font><span>會議室</span></p>
                                <p><font>•</font><span>辦公桌</span></p>
                                <p><font>•</font><span>多功能活動室</span></p>
                                <p><font>•</font><span>演講廳</span></p>
                            </a-col>
                            <a-col :span="12">
                               <h4><i>娛樂區域</i></h4>
                                <p><font>•</font><span>互動遊戲空間</span></p>
                                <p><font>•</font><span>音樂室</span></p>
                                <p><font>•</font><span>派對場地</span></p>
                                <p><font>•</font><span>閱讀空間</span></p>
                                <p><font>•</font><span>酒吧及燒烤區</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24"><br /></a-col>
                    <a-col :span="24" class="space-footer-text2">
                        <a-row>
                            <a-col :span="12" class="space_bottom_text">
                                <h4><i>休閒區域</i></h4>
                                <p><font>•</font><span>綠化休憩空間</span></p>
                                <p><font>•</font><span>戶內及戶外綠色社交空間</span></p>
                                <p><font>•</font><span>Crossfit空間</span></p>
                                <p><font>•</font><span>健身中心</span></p>
                            </a-col>
                            <a-col :span="12">
                                <h4><i>基本區域</i></h4>
                                <p><font>•</font><span>智能自動販賣機</span></p>
                                <p><font>•</font><span>小型市場</span></p>
                                <p><font>•</font><span>電子貯物櫃</span></p>
                                <p><font>•</font><span>公共廚房</span></p>
                                <p><font>•</font><span>智能自助洗衣及乾衣服務</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    
                    
                </div>
            </a-row>
            <div class="space_step_btn1" ><b>{{spaceBtnText}} ＞ </b></div>
            <div class="pcSpaceBg">
                <img :src="pngSpaces" width="100%"  alt="">
            
            </div>
            
        </a-col>
        <a-col class="mian">
            <div class="smart_end_title space_end_title ">
                <span >共享社區啟發<br/>— 創新。
                <img :src="blueArrow" style="position: absolute;width:100px;top:-80px;left:45%;margin-left: -50px;"/>
            </span>
            </div>
        </a-col>
        <a-col class="community mian" id="community">
            <a-col><img :src="pngCommunityTop" width="100%" alt="" class="pngCommunitytop"></a-col>
            <a-row class="community-text community-text-zh">
                <a-col class="community-title">互動社區<img :src="gifBlueSmile" class="community-smale" alt=""></a-col>
                <a-col>
                    只有工作，沒有娛樂？在創新斗室不可能出現！創新不是在象牙塔內閉門造車，而是令身心放鬆，回歸善於觀察的狀態，讓軼事激發靈感，觸發創新意念。因此，除了推廣協作創新及促進知識交流的活動外，我們更會定期舉辦消閒及社交活動，助你休息放空，為身心充電。密切留意創新斗室的活動消息！<br /><br />
                   我們持續追求創新和卓越。創新斗室社區由我們精心策劃及挑選，創造適合創科人才實踐意念、改變世界的生態圈。這裡不僅是休憩之所，更是觸發及實踐創新意念的社區，旨在助人才奮力前進、創造影響及推動突破的空間。
                </a-col>
            </a-row>
            <img :src="pngCommunity" width="100%" alt="" class="pngCommunity">
        </a-col>
        <a-col class="mian">
            <a-col class="together text-right ">
                <a-row>
                    <a-col :span="14"></a-col>
                    <a-col :span="10" class="together-title">
                        <div class="together-text text-center together-title-zh" style=" margin-left: -100px;">
                            攜手協作，走得更遠<br />
                           人多、更好辦事
                        </div>
                        <img :src="gitCircled" width="90%" alt="" class="together_title_gif">
                    </a-col>
                </a-row>
            </a-col>
        </a-col>
         <a-col class="mian">
            <Together></Together>
        </a-col>
        <a-col class="touch text-left" style="" id="touch">
            <a-row>
                <div class="mian">
                    <a-col class="touch-title">
                        聯絡<span></span>我們 <br />
                    </a-col>
                </div>
                <a-col class="touch-content">
                    <div class="mian" style="padding-left:0;">
                    	<a-col :span="16">
                        我們將於二零二一年第一季開始接受香港科技園夥伴公司的申請。
                        <br/>如有查詢，請電郵至 <a style="text-decoration: underline;color:#000;" href="mailto:innocell.general@hkstp.org">innocell.general@hkstp.org</a>
                        </a-col>
                        <div style="clear: both;"></div>
                    </div>
                </a-col>
                <a-col class="touch-footer mian">
                    香港新界大埔創新路1號
                </a-col>
                <div class="mian m-touch-img" > 
                    <a-col class="touch-img">
                        <img :src="pngMan" alt="">
                    </a-col>
                </div>
            </a-row>
        </a-col>
    </a-row>
    <a-back-top />
    <Dialog></Dialog>
</div>
</template>

<script>
import Nav from '@/components/zh-hk/Nav'
import Chatbot from '@/components/Chatbot'
import CoCarousel from '@/components/zh-hk/CoCarousel'
import SmartLiving from '@/components/zh-hk/SmartLiving'
import Together from '@/components/zh-hk/Together'
import Dialog from '@/components/Dialog'
import $ from 'jquery'
import global from '../global'
export default {
    name: 'Home',
    components: {
        Nav,
        Chatbot,
        CoCarousel,
        SmartLiving,
        Together,
        Dialog
    },
    data() {
        return {
            gifHeaderAnimation: require('../assets/img/gif/header_animation.gif'),
            gifBlueUnderline: require('../assets/img/cn/-cbGxqvw.png'),
            gifBlueUnderline2:require('../assets/img/cn/pgtWMFdA.png'),
            gifBlueExclaimation: require('../assets/img/gif/blue_exclaimation_gif.gif'),
            gifBlueSmile: require('../assets/img/gif/blue_smile_animation.gif'),
            gifYellowBox: require('../assets/img/cn/hs2ujsnA.png'),
            gitIntroCard: require('../assets/img/gif/intro_card.gif'),
            gitCircled: require('../assets/img/gif/circled.gif'),
            pngSpaces: require('../assets/img/spaces_background.png'),
            pngmobileSpaces:require('../assets/img/spacespcMoblieBg01.png'),
            pngCommunity: require('../assets/img/community_background.png'),
            pngMan: require('../assets/img/man.png'),
            blueArrow:require('../assets/img/gif/blue_arrow_gif.gif'),
            vimeoURL:'https://vimeo.com/468811485/3a7edbd5de',
            green_squiggle_animation:require('../assets/img/gif/yellowline.gif'),
            green_squiggle_animation2:require('../assets/img/gif/yellowline2.gif'),
            yellowzigzag:require('../assets/img/gif/yellowzigzag.gif'),
            videoUrl:require('../assets/img/header_animation.mp4'),
            spaceBottomImg:require('../assets/img/space_bottom_img.png'),
            gitIntrRigthBtn:require('../assets/img/right_black.png'),
            clientWidth:0,
            pngCommunityTop:require('../assets/img/community-top.png'),
            screenWidth:0,
            timer:false,
            screenWidths:0,
            isLoading:null,
            isVideo:2,
            SpaceBoxHeight:0,
            aid:1,
            spaceBtnText:'閱讀更多',
            isClick:false,
        }
    },
    methods: {
        introRightBtn(domeId){
            if(this.isClick){
                return ;
            }
            this.isClick = true;
            this.clientWidth = global.getClientWidth();
            $('.intro-item').removeClass("intro-active")
            console.log(domeId)
            if('intro-item02' == domeId){
                $('.intro-item02').css({"left":(this.clientWidth)+"px"})
                $(".intro-item01").animate({"left":"0px"},'normal');
                $(".intro-item01").addClass("intro-active")
            }else{
                $('.intro-item01').css({"left":(this.clientWidth)+"px"})
                $(".intro-item02").animate({"left":"0px"},'normal');
                $(".intro-item02").addClass("intro-active")
            }
           this.isClick = false;
        },
    },
    mounted() {
        window.localStorage.setItem('lang', 'hk')
        this.$cookies.set('language','zh_HK')
        var that = this
        that.clientWidth = global.getClientWidth();
        this.$nextTick(function(){
            let h = $(".intro_card").find('.intro-item').height();
            $(".intro_card_text").css({height:(h+120)+"px"})
            var items = document.getElementsByClassName("intro-item");
            if(items.length > 0){
                var i = 0;
                Array.prototype.forEach.call(items, function (element) {
                    if(i == 0){
                        element.style.left= "0px";
                        element.classList.add('intro-active');
                    }else{
                        element.style.left = that.clientWidth+"px";
                    }
                    i++;
                })
                
            }
            
            // 點擊
            $("body").on("click",".space_step_btn1",function(){
                that.aid++;
                if(that.aid > 3){
                    that.aid = 1;
                }
                var aid = that.aid;
                if(global.getScreenWidth() <= global.mobileWidth && (aid == 2 || aid == 3)){

                    $("#spaces").find(".spaces-text").css({"height":that.SpaceBoxHeight+"px"}) 
                }else{
                    $("#spaces").find(".spaces-text").css({"height":"auto"})
                }
                
                if(aid == 1){
                    $(".spaceStep3").hide();
                    $(".spaceStep1").show();
                    $("#spaces").removeClass("spacesHover");
                    that.spaceBtnText = "閱讀更多";
                }else if(aid == 3){
                     $(".spaceStep2").hide();
                     $(".spaceStep3").show();
                     that.spaceBtnText = "創新場所";
                }else{
                    $(".spaceStep2").show();
                    $(".spaceStep1").hide();
                    $("#spaces").addClass("spacesHover");
                    that.spaceBtnText = "單擊以查看不同的區域";
                }
            })
        })
        if(that.clientWidth <= 768){
            this.isVideo = 2;
        }else{
            this.isVideo = 1;
        }
         window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
            if(that.screenWidths == undefined){
                return ;
            }
            if(that.screenWidths <= 768){
                that.isVideo = 2;
            }else{
                that.isVideo = 1;
            }
        });
         window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                if(global.getScreenWidth() <= global.mobileWidth){
                    $(".spaceStep1").show();
                    $(".spaceStep2,.spaceStep3").hide();
                    $("#spaces").removeClass("spacesHover");
                    setTimeout(function(){
                        that.SpaceBoxHeight = $("#spaces").height()-72;
                    },50)
                 }else{
                    $("#spaces").removeClass("spacesHover");
                    $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                 }
            },100)
        }, false)
        // 手机点击
        $(document).on('touchstart','.click-btn',function(){
           $(this).css({transform:"rotate(10deg)"})
           var that = this;
           setTimeout(function(){
             $(that).css({transform:"rotate(0deg)"})
           },200)
        })
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  this.screenWidths = newVal;
                  
                  let h = $(".intro_card").find('.intro-item').height();
                  $(".intro_card_text").css({height:(h+120)+"px"}) 
                  var _this = this
                  $(".intro-item").css({left:this.clientWidth+"px"})
                  $(".intro-item.intro-active").css({left:"0px"})
                  _this.timer = true;
                  setTimeout(function(){
                     _this.clientWidth = global.getClientWidth();      
                     if(global.getScreenWidth() <= global.mobileWidth){
                        $(".spaceStep1").show();
                        $(".spaceStep2,.spaceStep3").hide();
                        $("#spaces").removeClass("spacesHover");
                        setTimeout(function(){
                            _this.SpaceBoxHeight = $("#spaces").height()-72;
                        },50)
                     }else{
                        $("#spaces").removeClass("spacesHover");
                        $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                     }
                     $("#spaces").find(".spaces-text").css({"height":"auto"})
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },

}
</script>

<style lang="less">

</style>
